import React, { useState, useEffect } from "react";
import "./App.css";

const ClientManagement = () => {
  const [clients, setClients] = useState([]);
  const [form, setForm] = useState({ name: "", surname: "", ico: "", company: "", email: "", description: "" });
  const [file, setFile] = useState(null);

  useEffect(() => {
    fetch("https://mafinek.cz/api/clients")  // Nové
      .then((res) => res.json())
      .then((data) => setClients(data));
  }, []);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.keys(form).forEach((key) => formData.append(key, form[key]));
    if (file) formData.append("contract", file);

    const response = await fetch("/api/clients", {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      const newClient = await response.json();
      setClients([...clients, newClient]);
      setForm({ name: "", surname: "", ico: "", company: "", email: "", description: "" });
      setFile(null);
    }
  };

  return (
    <div>
      <h1>Správa klientů</h1>
      <form onSubmit={handleSubmit}>
        <input name="name" placeholder="Jméno" value={form.name} onChange={handleChange} required />
        <input name="surname" placeholder="Příjmení" value={form.surname} onChange={handleChange} required />
        <input name="ico" placeholder="IČO" value={form.ico} onChange={handleChange} required pattern="\d{8}" />
        <input name="company" placeholder="Název firmy" value={form.company} onChange={handleChange} />
        <input type="email" name="email" placeholder="Email" value={form.email} onChange={handleChange} required />
        <textarea name="description" placeholder="Popis" value={form.description} onChange={handleChange} />
        <input type="file" onChange={handleFileChange} />
        <button type="submit">Přidat klienta</button>
      </form>
    </div>
  );
};

export default ClientManagement;
